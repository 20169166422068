import { Box, Typography, Grid, ListItemButton } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

export default function Footer() {
    return(
        <Box component="div" sx={{ p: 1 }}>
            <Box align='center' sx={{p:2}}>
                <Grid container 
                    style={{ position: "relative" }} 
                    justifyContent="space-around" 
                    alignItems="center" 
                    direction="row">
                    <Grid>
                        <Box component='a' href='https://www.instagram.com/snkr.garage/'>
                            <Box component="div" sx={{display: 'inline', p: 2}}>
                                    <InstagramIcon></InstagramIcon>
                            </Box>
                            <Typography>@snkr.garage</Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <Box component='a' href='https://www.tiktok.com/@snkr.garage'>
                            <Box component="div" sx={{display: 'inline'}}>
                                <FontAwesomeIcon icon={faTiktok} />
                            </Box>
                            <Typography sx={{pt:1}}>@snkr.garage</Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <Box component='a' href='https://www.youtube.com/channel/UCtazEsS3V-TrGArFwIgkuVg'>
                            <Box component="div" sx={{display: 'inline', p: 2}}>
                                <YouTubeIcon></YouTubeIcon>
                            </Box>
                            <Typography>@snkrgarage</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Typography variant="subtitle2" align="center">
                {'© ' + new Date().getFullYear() + ' '}
                Pierce Industries LLC. All Rights Reserved
            </Typography>
        </Box>
    );
}