import '../styles/globals.css'
import { Analytics } from '@vercel/analytics/react';
import type { AppProps } from 'next/app'
import Layout from '../components/layout'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import Head from "next/head"
import { ThemeProvider } from '@mui/material';
import { appTheme } from '../themes/blackandwhite';

config.autoAddCss = false

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={appTheme}>
      <Layout>
        <Head>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5059172687188118"
            crossOrigin="anonymous"></script>
          <link rel="shortcut icon" href='/SnkrGarageIcon.ico' />
        </Head>
        <Component {...pageProps} />
        <Analytics />
      </Layout>
  </ThemeProvider>
  )
}
