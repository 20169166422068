// Todo: https://javascript.works-hub.com/learn/how-to-create-a-responsive-navbar-using-material-ui-and-react-router-f9a01

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

const drawerWidth = 240;

export default function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        SNKR Garage
      </Typography>
      <Divider />
      <List>
        <ListItem key="Calendar" disablePadding>
          <ListItemButton component="a" href="/" sx={{ textAlign: 'center' }}>
              <ListItemText primary="Release Calendar" />
            </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', pt: 7 }}>
      <CssBaseline/>
      <AppBar component="nav">
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center" direction="row">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2}}
              >
              <MenuIcon />
              </IconButton>
            </Grid>
            {/* <Grid item>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1,  display: { xs: 'none', sm: 'block'} }}>
                  SNKR Garage
              </Typography>
            </Grid> */}
            <Grid item>
              <Box 
                component="img"
                src='/snkrGarageAvatar.png'
                sx={{
                  width: 55, 
                  height: 55,
                  display: { xs: 'none', sm: 'block'}
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block'},
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}